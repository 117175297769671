export const ANSWERS = [
  {
    id: 1,
    text:
      'The next period has the potential to be bright and shiny. You just have to start waking up when the sun rises, to enjoy it fully.',
  },
  {
    id: 2,
    text:
      'When is it that you will achieve all you set your mind up to do, you ask? They willcome, just enjoy every step of the way and learn to give yourself a small reward when you make progress. Big results come from little actions.',
  },
  {
    id: 3,
    text:
      'You will benefit from the wisdome and warmth of a close person. Be it in your personal of professional life, they will be a good companion to you and will bring you just the extra support you need to be your best self.',
  },
  {
    id: 4,
    text:
      'The sun will shine on your street too. Of course, it shines every day, you just have to be present and enjoy it.',
  },
  {
    id: 5,
    text:
      'A change is coming. It might take you by surprise or you might have been preparing it for a while now, but it’s coming and it’s sure to put you on track for achieving your best self.',
  },
  {
    id: 6,
    text:
      'You might’ve felt tired or overwhelmed for a while, but that’s about to come to an end. I see a time of relief and clarity. You will gain better control over your daily life and your overall goals and how to achieve them.',
  },
  {
    id: 7,
    text:
      'This is a time for creative endeavours. You don’t need to become Picasso in order to achieve this, you just have to let your mind wander through those corners where they don’t usually go and get creative with whatever comes your way.',
  },
  {
    id: 8,
    text:
      'Did you feel like you were not the main character in your life? Not anymore! This is the time for looking within and realize what you have to offer is exactly what the world needs. This is the time to write your story with yourself in the main character role.',
  },
  {
    id: 9,
    text:
      'You are centered. You have clarity. You are ready to take control and make things happen for yourself exactly as you need them to.',
  },
  {
    id: 10,
    text:
      'This is a time for love. You might experience a very fruitful period in your romantic relationship with your partner or just realize you are surrounded by supportive, loving and kind people ready to be there for you whenever you need it.',
  },
  {
    id: 11,
    text:
      'Over the next period, you will learn to ask for what you need. Ask for help. Communicate your needs and let things happen. You will be surprised by all the support you will receive from those around you.',
  },
  {
    id: 12,
    text:
      'I see a safe space in your near future. You might be in the process of creating a new warm and cozy home or maybe get confortable in your new office space. Whatever it might be, just know you can make it your own and you will feel right at home there.',
  },
  {
    id: 13,
    text:
      'There’s an opportunity for a new beginning in your future. It might even look a bit foolish if you’re too cautious about it, but don’t be afraid, it’s there for you to take the leap. Accept the opportunity and enjoy the results that come from it.',
  },
  {
    id: 14,
    text:
      'The wheel spins round and round, it’s always moving and always taking you to new and exciting places. You might feel like you’ve been here before. Embrace it, it’s your opportunity to do things differently this time, with a new and improved perspective.',
  },
  {
    id: 15,
    text:
      'I see strength in your future. It might be that you are improving your physical form,taking up a new professional opportunity or just working on improving yourself overall. Just know you’re doing it from a place of strength and potential.',
  },
  {
    id: 16,
    text:
      'Have you recently been on the verge about something? Did you have trouble deciding what’s best for you? Not anymore! Soon enough, things will shift and you will discover your new power and a sense of clarity that will put you on the right course.',
  },
  {
    id: 17,
    text:
      'Relaxation is coming! If you’ve felt tired or had lots of things to do on your projects lately, this is all about to come to an end soon and you will enjoy some well deserved vacation days.',
  },
  {
    id: 18,
    text:
      'Yes, they do love you! No need to break flower petals anymore. Know that person that you cannot stop thinking about? Well, good news! They can’t stop thinking about you either. So don’t waste any more time. Put those flowers in a bouquet and deliver them right to their door.',
  },
  {
    id: 19,
    text:
      'You might not be a morning person, but you know how to make your days longer by enjoying every second of every day. Keep going like that and remember – no matter if it’s the sun or the moon, a light is always shining upon you.',
  },
  {
    id: 20,
    text:
      'There are good days and there are challenging days. It’s not so much about changing the challenging one as it is about starting to look at them as opportunities. Remember, we don’t see the world as it is, we see it as we are.',
  },
  {
    id: 21,
    text:
      'Reality is just a construct of our minds. You will soon start seeing things in more colourful nuances. Some people might believe you have some rose coloured glasses on, but you will know you just hacked life by switching perspectives.',
  },
  {
    id: 22,
    text:
      'Have you recently had problems with your devices? Maybe they crashed more often or you were just clumsy and dropped them on the floor. Whichever it is, take it as a message – it’s an invitation to disconnect and reconnect to things and people around you.',
  },
];
