import React, { useEffect, useRef, useState } from 'react';
import './App.scss';
import hand from './assets/Hand-1.png';
import handGlow from './assets/Hand-glow-1.png';
import scanner from './assets/scanner.png';
import Modal from 'react-modal';
import palmGif from './assets/pam-scan.gif';
import { ANSWERS } from './answers.contants';
import useSound from 'use-sound';
const sound = require('./assets/clash.mp3');

const modalStyles = {
  overlay: {
    backgroundColor: 'unset',
  },
};

function App() {
  const [isTouching, setIsTouching] = useState(false as boolean);
  const [isScanning, setIsScanning] = useState(false as boolean);
  const [isModalOpen, setIsModalOpen] = useState(false as boolean);
  const isTouchingRef = useRef(isTouching);

  const [play, { stop }] = useSound(sound, { playbackRate: 0.75, loop: true });

  const handleTouchStart = (e: any) => {
    setIsTouching(true);
  };

  const handleTouchEnd = (e: any) => {
    setIsTouching(false);
  };

  useEffect(() => {
    let timer: any;
    isTouchingRef.current = isTouching;
    if (isTouching) {
      timer = setTimeout(() => {
        if (isTouchingRef.current) {
          setIsScanning(true);
        }
      }, 2000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [isTouching]);

  useEffect(() => {
    let timer: any;
    if (isTouching) {
      play();
      timer = setTimeout(() => {
        stop();
        setIsScanning(false);
        setIsTouching(false);
        setIsModalOpen(true);
      }, 4000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [isScanning]);

  useEffect(() => {
    document.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });
  }, []);

  return (
    <div className="App">
      <div className="handContainer">
        <img
          src={isTouching ? handGlow : hand}
          className="hand"
          alt=""
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
        />
      </div>
      <p className="incentive">Rest your palm here</p>
      {isScanning && <img src={scanner} className="scanner" alt="" />}
      <Modal
        isOpen={isModalOpen}
        // onRequestClose={() => setIsModalOpen(false)}
        style={modalStyles}
        className="modalStyle"
        ariaHideApp={false}
      >
        <div className="modalContent">
          <img src={palmGif} alt="" />
          <p className="title">Palm Reader Analysis</p>
          <div className="content">
            <p className="text">
              {ANSWERS[Math.floor(Math.random() * 5)].text}
            </p>
          </div>
          <div className="modalActions">
            <div className="animated-button-no">
              <button
                className="button-73"
                onClick={() => setIsModalOpen(false)}
              >
                Not actually
              </button>
            </div>
            <div className="animated-button">
              <button
                className="button-73"
                onClick={() => setIsModalOpen(false)}
              >
                Cool
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default App;
